<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="main-col">
        <section class="first">
          <div class="main-title">
            <div>
              <h2>La conche de Trawenagh</h2>
              <div class="sub-title">
                <h3>Terroir de l’huître Ostra Regal</h3>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <p>
              Située dans le comté de Donegal au nord-ouest de l’Irlande, la conche de Trawenagh donne également
              naissance aux huîtres Sentinelle.
            </p>
            <p>
              Comme les conches de Bannow et de Dundrum, Trawenagh présente des caractéristiques organoleptiques
              extraordinaires. La configuration d’enclave de la conche de Trawenagh, permet de retenir les planctons et
              les nutriments nécessaires au développement des huîtres.
            </p>
            <p>
              La conche se découvre entièrement à chaque marée. Bien qu’il n’y ait pas de rivières approvisionnant la
              baie, de petits ruisseaux permettent une entrée d’eau douce régulière. Ces spécificités exceptionnelles
              font de la conche de Trawenagh un terroir de premier choix pour l’élevage d’huîtres de grande qualité.
            </p>
            <img :src="currentImageUrl" loading="lazy" alt="trawenagh-bay">
<!--            <img :src="currentImageUrl1" loading="lazy" alt="trawenagh-bay">-->
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import CategoryBanner from "@/components/CategoryBanner.vue";
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/services/utils";

export default {
  name: "Trawenagh",
  data() {
    return {
      currentImageUrl: '',
      srcset: 'about/trawenagh/trawenagh-small.jpg 320w,about/trawenagh/trawenagh-medium.jpg 640w,about/trawenagh/trawenagh-big.jpg 1280w',
    }
  },
  beforeMount() {
    this.$store.dispatch('metaData/getMetaData', 14)
  },
  computed: {
    ...mapGetters('metaData', ['getMeta']),
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta: [
        // meta données générales
        {name: 'title', content: this.getMeta.meta_title},
        {name: 'description', content: this.getMeta.meta_desc},
        {name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        {property: 'og:type', content: "website"},
        {property: 'og:url', content: "https://lafamilleboutrais.com/"},
        {property: 'og:title', content: this.getMeta.meta_title},
        {property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {CategoryBanner},
  mounted() {
    this.$store.commit('category/setCategory', 14)
    this.updateImageSrc();
    window.addEventListener('resize', this.updateImageSrc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageSrc);
  },
  methods: {
    ...mapActions('metaData', ['getMetaData']),
    updateImageSrc() {
      this.currentImageUrl = Utils.getImageSrc(this.srcset);
    },
  },
}
</script>

<style lang="scss" scoped>


.container {
  @include flex(space-between, center, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 2% 5%;
}

.main-col {
  @include flex(space-between, center, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 90vw;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
  }

  section {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    margin-bottom: 20px;

    .main-title {
      @include flex(flex-start, center, $gap: 8px);
      position: relative;

      h2 {
        color: $Black;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        letter-spacing: 1px;
      }

      img {
        width: 10%;
      }
    }

    .sub-title {
      margin-bottom: 25px;

      h3 {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .wrapper {
      @include flex(space-between, center, column, $gap: 25px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
      }
      @media screen and (max-width: 575.99px) {
        gap: 10px;
      }

      p {
        width: 0;
        min-width: 100%;
      }

      .name {
        font-weight: 700;
      }

      .buttons__container {
        @include flex(flex-start, flex-start, column, $gap: 8px);
        flex: 1;
        max-width: 200px;
        @media screen and (max-width: 767.99px) {
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          max-width: unset;
        }

        .button {
          font-size: 15px;
          padding: 10px 10px;
          letter-spacing: .5px;
          flex: 1;
          @media screen and (max-width: 767.99px) {
            width: fit-content;
            flex: auto;
          }
          @media screen and (max-width: 575.99px) {
            width: 100%;
          }
        }
      }
    }

  }

  .next-step {
    margin-left: auto;
  }
}
</style>