<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="main-col">
        <section class="text">
          <div class="main-title">
            <div>
              <h2>La conche de dundrum</h2>
              <div class="sub-title">
                <h3>Dun Droma</h3>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <p>
              La conche de Dundrum est située en Irlande du Nord dans le comté de Down. Elle est dominée par les
              majestueuses montagnes de Mourne. Entre Dundrum et Newcastle où s’étend la réserve naturelle nationale
              Murlough, la plus grande étendue de dunes de toute l’Irlande.
            </p>
            <p>
              Ce sont les huîtres Sentinelle qui vont être élevées dans la conche de Dundrum. La taille de la conche est
              bien supérieure à celle de Bannow, la densité d’élevage est donc encore plus faible. Comme la surface de
              la baie est immense, une petite houle de secteur se forme et roule doucement les huîtres dans les poches.
              Cela permet d’obtenir une coquille ronde, polie et dure. Sur le côté organoleptique, on retrouve un
              mélange d’eau douce et d’eau de mer permettant, comme à Bannow, de créer une huître super spéciale très
              charnue. En revanche, l’absence de cours d’eau ou de rivière donne à l’huître un goût plus iodé que sucré.
            </p>
            <img :src="currentImageUrl" loading="lazy" alt="dundrum-bay">
            <img :src="currentImageUrl1" loading="lazy" alt="dundrum-bay">
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import CategoryBanner from "@/components/CategoryBanner.vue";
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/services/utils";

export default {
  name: "Dundrum",
  data () {
    return {
      currentImageUrl: '',
      currentImageUrl1: '',
      srcset: 'about/dundrum/dundrum_small.png 320w,about/dundrum/dundrum_medium.png 640w,about/dundrum/dundrum_big.png 1280w',
      srcset2: 'about/dundrum/dundrum2_small.png 320w,about/dundrum/dundrum2_medium.png 640w,about/dundrum/dundrum2_big.png 1280w',
    }
  },
  beforeMount () {
    this.$store.dispatch('metaData/getMetaData', 15)
  },
  computed: {
    ...mapGetters('metaData', ['getMeta']),
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta:[
        // meta données générales
        {  name: 'title', content: this.getMeta.meta_title},
        { name: 'description', content: this.getMeta.meta_desc},
        { name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMeta.meta_title},
        { property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {CategoryBanner},
  mounted() {
    this.$store.commit('category/setCategory', 15)
    this.updateImageSrc();
    window.addEventListener('resize', this.updateImageSrc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageSrc);
  },
  methods: {
    ...mapActions('metaData', ['getMetaData']),
    updateImageSrc() {
      this.currentImageUrl = Utils.getImageSrc(this.srcset);
      this.currentImageUrl1 = Utils.getImageSrc(this.srcset2);
    },
  },
}
</script>

<style lang="scss" scoped>


.container {
  @include flex(space-between, center, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 2% 5%;
}

.main-col {
  @include flex(space-between, center, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 90vw;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
  }

  section {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    margin-bottom: 20px;

    .main-title {
      @include flex(flex-start, center, $gap: 8px);
      position: relative;

      h2 {
        color: $Black;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        letter-spacing: 1px;
      }

      img {
        width: 10%;
      }
    }

    .sub-title {
      margin-bottom: 25px;

      h3 {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .wrapper {
      @include flex(space-between, center, column, $gap: 25px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
      }
      @media screen and (max-width: 575.99px) {
        gap: 10px;
      }

      p{
        width: 0;
        min-width: 100%;
      }

      .name {
        font-weight: 700;
      }

      .buttons__container {
        @include flex(flex-start, flex-start, column, $gap: 8px);
        flex: 1;
        max-width: 200px;
        @media screen and (max-width: 767.99px) {
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          max-width: unset;
        }

        .button {
          font-size: 15px;
          padding: 10px 10px;
          letter-spacing: .5px;
          flex: 1;
          @media screen and (max-width: 767.99px) {
            width: fit-content;
            flex: auto;
          }
          @media screen and (max-width: 575.99px) {
            width: 100%;
          }
        }
      }
    }

  }

  .next-step {
    margin-left: auto;
  }
}
</style>