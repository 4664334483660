<template>
  <main>
    <CategoryBanner/>

    <div class="shop-wrapper">
      <Breadcrumb/>
      <div class="select-container">
        <div class="select-wrapper">
          <select name="sort-products" aria-label="Sort products by :" @change="sortProducts">
            <option value="initial">Trier par</option>
            <option value="priceUp">Prix — croissant</option>
            <option value="priceDown">Prix — décroissant</option>
          </select>
          <span></span>
        </div>
      </div>
      <div v-if="stateLoading" class="spinner_container">
        <Spinner></Spinner>
      </div>
      <div v-else class="products_container">
        <product-card :products="getProductsList"></product-card>
      </div>
      <section class="container about">
        <img :src="require('@/assets-2/'+ getCategory.meta_image)" alt="Cover image" class="about__img">
        <div class="about__container">
          <h2>{{getMeta.title}}</h2>
          <p>
            {{getMeta.text}}
          </p>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import {getAPI} from "@/services/axios-api";
const ProductCard = () => import ("@/components/ProductCard.vue")
const CategoryBanner = () => import ("@/components/CategoryBanner.vue")
const Breadcrumb = () => import ("@/components/Breadcrumb.vue")
const Spinner = () => import ("@/components/Spinner.vue")
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/services/utils";

export default {
  name: "Shop",
  beforeMount () {
    this.$store.dispatch('metaData/getMetaData', this.getCategory.id)
  },
  computed: {
    ...mapGetters('products', ['getProductsList']),
    ...mapGetters('pricelist', ['getPricelistItems']),
    ...mapGetters('auth', ['getLocation']),
    ...mapGetters('category', ['getCategory']),
    ...mapGetters(['stateLoading']),
    ...mapGetters('metaData', ['getMeta']),
  },
  metaInfo() {
    return {
      title : this.getMeta.template,
      meta:[
        // meta data to google/edge/fireFox...
        { name: 'title', content: this.getMeta.meta_title},
        { name: 'description', content: this.getMeta.meta_desc},
        { name: 'robots', content: 'index,follow'},
          // to SEO FaceBook & Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMeta.meta_title},
        { property: 'og:description', content: this.getMeta.meta_desc},
        { property: 'og:image', content: 'http://51.178.140.145:8090/home/lfb_home_pres.webp'},
        // { property: 'og:image', content: 'http://localhost:8080/src/asset/'+ this.getCategory.meta_image},
      ],
    }
  },
  components: {Spinner, ProductCard, CategoryBanner, Breadcrumb},
  data() {
    return {
      filterMaxPrice: 0,
      listCategory:[],
      srcset: 'about/bannow/bannow_small.png 320w,about/bannow/bannow_medium.png 640w,about/bannow/bannow_big.png 1280w',
      currentImageUrl: ''
    }
  },
  props: {
    category: {
      type: [String],
      required: true,
    }
  },

  methods: {
    ...mapActions('products', ['getProducts']),
    ...mapActions('metaData', ['getMetaData']),
    updateImageSrc() {
      this.currentImageUrl = Utils.getImageSrc(this.srcset);
    },
    sortProducts: function (event) {
      let sorting = event.target.value
      if (sorting == 'priceDown') {
        this.getProductsList.sort((a, b) => {
          return (a.lower_price < b.lower_price) ? 1 : (a.lower_price === b.lower_price) ? ((a.name < b.name) ? 1 : -1) : -1
        })
      } else if (sorting == 'priceUp') {
        this.getProductsList.sort((a, b) => {
          return (a.lower_price > b.lower_price) ? 1 : (a.lower_price === b.lower_price) ? ((a.name > b.name) ? 1 : -1) : -1
        })
      }
    },
  },
  watch: {
    '$route.params': {
      handler() {
        this.$store.commit('category/setCategory', this.category)
        this.$store.dispatch('metaData/getMetaData', this.getCategory.id)
        const data = {
          'category': this.getCategory.id,
          'prices': this.getPricelistItems
        }
        this.getProducts(data);
      },
      immediate: true,
    },
    getLocation: function () {
      const data = {
        'category': this.getCategory.id,
        'prices': this.getPricelistItems
      }
      this.getProducts(data);
    }
  },
  mounted() {
    // Dynamic select width
    const selects = [...document.querySelectorAll('.select-container select')]
    selects.forEach(select => {
      select.addEventListener('change', (event) => {
        let tempSelect = document.createElement('select'),
            tempOption = document.createElement('option');

        tempOption.textContent = event.target.options[event.target.selectedIndex].text;
        tempSelect.style.cssText += `
			      visibility: hidden;
			      position: fixed;
			    `;
        tempSelect.appendChild(tempOption);
        event.target.after(tempSelect);

        const tempSelectWidth = tempSelect.getBoundingClientRect().width;
        event.target.style.width = `${tempSelectWidth + 10}px`;
        tempSelect.remove();
      });
      select.dispatchEvent(new Event('change'));
    })
    this.updateImageSrc();
    window.addEventListener('resize', this.updateImageSrc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageSrc);
  },
}
</script>

<style lang="scss" scoped>

:deep .breadcrumb {
  padding: 20px 0;

}

//TODO end component

main {
  @include flex(flex-start, flex-start, column);
}

.select-container {
  @include flex(flex-end, flex-start, row, wrap, $gap: 1rem);
  padding: 1rem 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 0 0 2vw;
  }

  .select-wrapper {
    @include flex(space-between, center);
    position: relative;

    span {
      @include position(52.5%, $right: 7.5px);
      display: block;
      width: 15px;
      height: 15px;
      translate: 0 -50%;
      pointer-events: none;
      background-color: $LightBrown;
      clip-path: polygon(50% 75%, 0 25%, 100% 25%);
    }

    select {
      appearance: none;
      background-color: transparent;
      border: solid 1px $LightBrown;
      margin: 0;
      padding: .25rem calc(.5rem + 20px) .25rem .5rem;
      outline: none;
      border-radius: 10px;
      font-size: 15px;
      @media screen and (max-width: 767.99px) {
        max-width: 30vw !important;
      }

      &::-ms-expand {
        display: none;
      }

      &:focus {
        background-color: $LightBrown;
        color: $White;

        & + span {
          background-color: $White;
        }
      }

      option {
        background-color: $White;
        color: $Black;

        &:first-child {
          display: none
        }

        &:checked {
          background-color: $LightBrown;
          color: white;
        }
      }
    }
  }
}

.shop-wrapper {
  padding: 0 .75rem;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 2vh 7.5%;
  }
}

.products_container {
  @include flex(space-between, flex-start);
  position: relative;
  gap: 7.5%;
  width: 100%;

  :deep .card {
    @media screen and (min-width: 1200px) {
      max-width: calc(25% - calc(6vw / 4));
    }
  }
}

.spinner_container {
  @include flex($justify: center, $align: center, $direction: row, $wrap: nowrap, $gap: 0);
  position: relative;
  gap: 7.5%;
  width: 100%;
}

.filters-wrapper {
  &.mobile {
    @media screen and (max-width: 767.99px) {
      display: block;
      margin-right: auto;
      position: relative;

      &[open] summary {
        background-color: $LightBrown;
        color: $White;

        span {
          background-color: $White;
        }
      }

      summary {
        @include flex(space-between, center);
        position: relative;
        border: solid 1px $LightBrown;
        padding: .25rem calc(.5rem + 20px) .25rem .5rem;
        outline: none;
        border-radius: 10px;
        font-size: 15px;

        span {
          @include position(52.5%, $right: 7.5px);
          display: block;
          width: 15px;
          height: 15px;
          translate: 0 -50%;
          pointer-events: none;
          background-color: $LightBrown;
          clip-path: polygon(50% 75%, 0 25%, 100% 25%);
        }
      }

      .filters-container {
        @include position(105%, 0);
        padding: 3vw 3.5vw;
        width: 50vw;
        max-width: unset;
        z-index: 1;
        background-color: $White;
        border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .5);

        .filter_range {
          width: 80%;
        }
      }
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 767.99px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    @include position($position: sticky, $top: 30vh);
    flex: 0 0 25%;
  }
  @media screen and (min-width: 1200px) {
    flex: 0 0 15%;
  }

  .filters-container {
    @include flex(flex-start, flex-start, column, $gap: .5rem);

    .filter {
      @include flex(flex-start, flex-start, column, $gap: .75rem);
      width: 100%;

      &_name {
        width: 100%;
        position: relative;

        &::after {
          @include position($bottom: -4px, $left: 0);
          width: 100%;
          height: 2px;
          background-color: $LightBrown;
        }

        h3 {
          color: $Black;
          text-align: left;
          font-weight: 700;
        }
      }

      &_list {
        @include flex(flex-start, flex-start, column, $gap: .2rem);

        li label {
          @include flex(flex-start, center, row, nowrap, $gap: .25rem);
          cursor: pointer;

          input[type="radio"] {
            appearance: none;
            background-color: $t;
            margin: 0;
            width: 13px;
            height: 13px;
            position: relative;
            cursor: pointer;

            &::before {
              @include position(50%, 50%);
              translate: -50% -50%;
              width: 6.5px;
              height: 6.5px;
              border-radius: 50%;
              background-color: $LightBrown;
              scale: 0;
              transition: .2s ease-in-out;
            }

            &::after {
              @include position(50%, 50%);
              translate: -50% -50%;
              height: 100%;
              width: 100%;
              border-radius: 50%;
              border: solid 1px $LightBrown;
            }

            &:checked::before {
              scale: 1;
            }
          }
        }
      }

      &_range {
        width: 100%;

        input[type="range"] {
          appearance: none;
          background-color: $Blue;
          width: 100%;
          height: 5px;
          cursor: e-resize;

          &::-webkit-slider-runnable-track, &::-moz-range-track {
            background: $Blue;
            height: 5px;
          }

          &::-webkit-slider-thumb {
            appearance: none;
            background-color: $White;
            border: solid 2px $Black;
            height: 12px;
            width: 12px;
            border-radius: 50%;
          }

          &::-moz-range-thumb {
            appearance: none;
            background-color: $White;
            border: solid 2px $Black;
            height: 12px;
            width: 12px;
            border-radius: 50%;
          }
        }

        .labels {
          position: relative;
          width: 100%;
          font-size: small;

          &-min {
            @include position($top: 0, $left: 0);
            translate: -50%;
          }

          &-max {
            @include position($top: 0, $right: 0);
            translate: 50%;
          }
        }
      }
    }
  }
}

.container {
  position: relative;
  width: 90vw;
  max-width: 1200px;
  margin: 75px auto 0;
}

.about {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 30px 0;

  @media screen and (min-width: 992px) {
    padding: 30px;
  }

  @media screen and (min-width: 1250px) {
    padding: 30px calc((99vw - 1200px) / 4) 30px calc((99vw - 1200px) / 2);
  }

  &__img {
    @include position(0, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &__container {
    @include flex(flex-start, center, column, $gap: 20px);
    position: relative;
    z-index: 2;
    min-width: 310px;
    width: 90%;
    margin: 0 auto;
    padding: 25px;
    border-radius: 10px;
    backdrop-filter: blur(10px);


    @media screen and (max-width: 991.99px) {
      background-color: rgba(255, 255, 255, .8);
    }

    @media screen and (min-width: 992px) {
      max-width: 70%;
      margin-left: auto;
      margin-right: 0;
      align-items: flex-start;
      background-color: rgba(255, 255, 255, .7);
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
      color: $Black;
    }

    p {
      color: #6E747B;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.5;
    }
  }
}
</style>