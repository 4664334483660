<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="main-col">
        <section class="first" id="online-command">
          <div class="main-title">
            <img v-lazy="handraw_ordinateur_marron" alt="Icone fraicheur">
            <div>
              <h2>Commande en ligne</h2>
              <div class="sub-title">
                <h3>Où souhaitez-vous faire livrer ?</h3>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <p>
              Avant de passer commande sur notre site, il est essentiel d'indiquer votre adresse de livraison, car c'est
              la première étape lorsque vous accédez à notre site. Cette étape est nécessaire pour vous afficher des
              tarifs personnalisés en fonction de votre zone de livraison.
            </p>
            <p>
              Pour indiquer votre adresse de livraison, vous devez simplement entrer votre code postal dans les champs
              prévus à cet effet sur la page d'accueil du site. Si vous avez déjà un compte client sur
              lafamilleboutrais.com, vous pouvez vous connecter en cliquant sur l'icône "connexion".
            </p>
            <p>
              Une fois que vous avez renseigné votre adresse, la ville de livraison correspondante s'affichera sous
              l'icône "livraison". Vous pourrez alors commencer à parcourir notre large sélection de produits.
            </p>
            <p>
              Vous pouvez modifier votre adresse de livraison à tout moment en cliquant sur l'icône "livraison". Vous
              pouvez également modifier l'adresse de livraison au moment de valider votre panier, juste avant de
              procéder au paiement de votre commande. Les frais de transport seront recalculés en fonction de votre zone
              de livraison.
            </p>
            <img class="image" v-lazy="location" alt="location">
          </div>
        </section>
        <section class="second" id="sea-direct">
          <div class="main-title">
            <img v-lazy="handraw_ancre_marron" alt="Icone fraicheur">
            <div>
              <h2>En direct de la mer</h2>
              <div class="sub-title">
                <h3>Du producteur à votre assiette</h3>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <p>
              Nous sommes fiers de vous offrir des huîtres de qualité supérieure sur notre site, toutes issues de notre
              propre production ostréicole en Bretagne ou en Irlande. Si vous souhaitez en savoir plus sur l'élevage de
              nos huîtres, nous vous invitons à vous rendre sur notre page
              <router-link :to="{name: 'WhoAreWe'}">“Qui sommes-nous ?”</router-link>
              .
            </p>
            <p>
              Nous sommes ainsi engagés dans la promotion de l'économie locale en travaillant exclusivement avec des
              partenaires de la région. Tous nos produits de la mer, y compris les coquillages et les crustacés, sont
              directement issus de nos partenaires locaux, qu'ils soient pêcheurs ou producteurs. Cette approche nous
              permet de garantir la fraîcheur de nos produits et de soutenir les entreprises locales. Nous sommes
              convaincus que cela nous permet aussi de proposer une expérience gustative authentique et de qualité
              supérieure à nos clients.
            </p>
            <p>
              Nous sommes fiers de faire partie de cette communauté et nous sommes heureux de pouvoir partager notre
              passion pour les produits de la mer avec vous. Nous espérons que vous apprécierez nos huîtres et que vous
              serez inspiré pour essayer nos autres délicieux fruits de mer. Si vous avez des questions sur notre
              production ou sur l'un de nos partenaires, n'hésitez pas à nous contacter. Nous serons heureux de vous
              aider et de vous donner toutes les informations dont vous avez besoin pour faire un choix éclairé.
            </p>
            <img class="image" v-lazy="oyster_ship" alt="oyster-ship">
          </div>
        </section>
        <section class="third" id="granted-fresh">
          <div class="main-title">
            <img v-lazy="handraw_fraicheur_marron" alt="Icone fraicheur">
            <div>
              <h2>Fraîcheur garantie</h2>
              <div class="sub-title">
                <h3>Des produits sorties la veille de l’eau</h3>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <p>
              Chez nous, la fraîcheur de nos produits est une priorité absolue. Une fois que vous avez validé votre
              commande, nos équipes se mettent immédiatement au travail pour préparer vos colis la veille de la date de
              livraison que vous avez choisie. Tous nos produits de la mer, y compris les huîtres, sont pêchés le matin
              même dans nos bassins, puis ils sont triés, emballés et reconditionnés dans un emballage isotherme de
              qualité.
            </p>
            <p>
              Pour garantir la qualité et la fraîcheur de vos produits, nous ajoutons également un gel pack réfrigérant
              à chaque colis isotherme. Cela permet de maintenir une température optimale pour la durée de la livraison,
              soit un minimum de 24 heures.
            </p>
            <p>
              Une fois emballée, votre commande est récupérée par notre transporteur partenaire en début d'après-midi,
              au sein de notre centre d'emballage. Nous avons choisi un transporteur spécialisé dans la livraison de
              produits alimentaires, équipé de camions frigorifiques pour assurer que vos produits arrivent à votre
              domicile en parfait état.
              Bien que notre transporteur s'engage à livrer votre commande avant 13 heures, nous vous recommandons
              vivement de prévoir une journée supplémentaire pour la livraison de vos commandes. Cela vous permettra
              d'être certain(e) de recevoir vos produits de la mer frais et en parfait état, sans avoir à vous soucier
              d'un éventuel retard ou imprévu lié à la livraison. Nous prenons toutes les mesures nécessaires pour
              garantir que votre commande sera préparée et expédiée dans les meilleurs délais, mais en raison de
              circonstances imprévues telles que les conditions météorologiques ou les problèmes de transport, il est
              toujours préférable de prévoir une journée supplémentaire pour la livraison.
            </p>
          </div>
        </section>
        <section class="fourth" id="express-delivery">
          <div class="main-title">
            <img v-lazy="handraw_transporteur_marron" alt="Icone fraicheur">
            <div>
              <h2>Livraison Express</h2>
              <div class="sub-title">
                <h3>Entre 24h et 48h</h3>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <p>
              Nous collaborons avec différents transporteurs tels que Chronofresh, France Express, etc. pour assurer la
              livraison de vos commandes du lundi au vendredi. Les livraisons sont possibles du mardi au samedi, à
              l'exception des jours fériés. Pour une livraison le lendemain, votre commande doit être validée avant 11
              heures. Les horaires de livraison s'étendent généralement de 8 heures à 13 heures, bien que des retards
              puissent survenir pendant la journée ou de 1 journée. C'est pourquoi nous vous recommandons de prévoir une
              journée de sécurité pour le jour de votre dégustation.
            </p>
            <p>
              Par exemple, si vous prévoyez de déguster vos huîtres le soir du 10 juillet, il est préférable de
              sélectionner le 8 ou le 9 juillet pour la livraison.
            </p>
            <p>
              NNos produits peuvent être conservés au frais avant la dégustation. Les huîtres peuvent être conservées au
              frais pendant un minimum de 5 jours, tandis que les coquillages et les crustacés vivants peuvent être
              conservés au frais durant un minimum de 48 heures. Pour en savoir plus sur la conservation de nos
              produits, veuillez consulter notre article dédié.
            </p>
            <p>
              Actuellement, nos transporteurs ne peuvent assurer la livraison qu'en France métropolitaine et en Corse.
            </p>
          </div>
        </section>
        <section class="fifth">
          <div class="main-title">
            <img v-lazy="handraw_transporteur_marron" alt="Icone fraicheur">
            <div>
              <h2>Le service Chronofresh</h2>
              <div class="sub-title">
                <h3>Spécialiste du transport frigorifique</h3>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="video">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/AGEbDfPwmK8"
                      title="YouTube video player" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
            </div>
            <p>
              Nous confions généralement l'acheminement de vos commandes à notre prestataire Chronofresh, une filiale du
              groupe Chronopost spécialisée dans la livraison express de produits frais. Chronofresh garantit le respect
              de la chaîne du froid durant tout le transport de votre colis.
            </p>
            <p>
              Pour maintenir une température idéale, votre colis est transporté dans des camions frigorifiques dont la
              température est maintenue entre 0°C et 2°C pendant toute la durée du transport.
            </p>
            <p>
              La remise de votre colis se fait en main propre et nécessite une signature du destinataire. Il est donc
              important de vous assurer qu'une personne sera présente à l'adresse de livraison le jour prévu. En cas
              d'absence ou d'impossibilité de livrer pour d'autres raisons, un avis de passage est laissé à votre
              domicile pour vous informer et une nouvelle livraison peut être organisée dès le lendemain. Votre commande
              reste sous la garde de Chronofresh même en cas de retard.
            </p>
            <p>
              Vous pouvez suivre l'avancée de votre colis à chaque étape grâce au suivi en ligne disponible sur le site
              de Chronopost et recevoir des notifications par e-mail et SMS.
            </p>
            <p>
              Chronofresh vous informe par e-mail et SMS de la date de livraison précise dès que votre colis est pris en
              charge. Avec Chronofresh, vos produits sont conservés à une température maîtrisée pendant toute la durée
              du transport, quel que soit le délai de livraison (maximum 48 heures).
            </p>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import handraw_ordinateur_marron from '@/assets-2/icons/handdraw/handraw_ordinateur_marron.svg'
import location from '@/assets-2/about/how_it_work/location.webp'
import handraw_ancre_marron from '@/assets-2/icons/handdraw/handraw_ancre_marron.svg'
import oyster_ship from '@/assets-2/about/how_it_work/oyster_ship.webp'
import handraw_fraicheur_marron from '@/assets-2/icons/handdraw/handraw_fraicheur_marron.svg'
import handraw_transporteur_marron from '@/assets-2/icons/handdraw/handraw_transporteur_marron.svg'

const CategoryBanner = () => import ("@/components/CategoryBanner.vue")

export default {
  name: "HowItWork",
  data () {
    return {
      handraw_ordinateur_marron,
      location,
      handraw_ancre_marron,
      oyster_ship,
      handraw_fraicheur_marron,
      handraw_transporteur_marron,
    }
  },
  beforeMount () {
    this.$store.dispatch('metaData/getMetaData', 9)
  },
  computed: {
    ...mapGetters('metaData', ['getMeta']),
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta:[
        // meta données générales
        {  name: 'title', content: this.getMeta.meta_title},
        { name: 'description', content: this.getMeta.meta_desc},
        { name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMeta.meta_title},
        { property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {CategoryBanner},
  mounted() {
    if (this.$route.hash) {
      let element = document.querySelector(this.$route.hash);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }
    this.$store.commit('category/setCategory', 9)
  },
  methods: {
    ...mapActions('metaData', ['getMetaData']),
  },
}
</script>

<style lang="scss" scoped>
.container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 2% 7% 0% 7%;
}

.video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.main-col {
  @include flex(flex-start, flex-start, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 90vw;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
  }

  section {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    margin-bottom: 20px;

    .main-title {
      @include flex(flex-start, center, $gap: 8px);
      position: relative;
      margin-bottom: 25px;

      h2 {
        color: $Black;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        letter-spacing: 1px;
      }

      img {
        width: 10%;
      }
    }

    .sub-title {

      h3 {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .wrapper {
      @include flex(space-between, flex-start, column, $gap: 25px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
      }
      @media screen and (max-width: 575.99px) {
        gap: 10px;
      }

      .name {
        font-weight: 700;
      }

      .buttons__container {
        @include flex(flex-start, flex-start, column, $gap: 8px);
        flex: 1;
        max-width: 200px;
        @media screen and (max-width: 767.99px) {
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          max-width: unset;
        }

        .button {
          font-size: 15px;
          padding: 10px 10px;
          letter-spacing: .5px;
          flex: 1;
          @media screen and (max-width: 767.99px) {
            width: fit-content;
            flex: auto;
          }
          @media screen and (max-width: 575.99px) {
            width: 100%;
          }
        }
      }
    }

  }

  .next-step {
    margin-left: auto;
  }
}
</style>