<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="main-col">
        <section class="first">
          <div class="main-title">
            <h2>Un récit d’explorateur</h2>
          </div>
          <div class="sub-title">
            <h3>Corsaires malouins</h3>
          </div>
          <div class="wrapper">
            <p>
              Notre histoire démarre à Saint-Malo, en Bretagne, le port d’attache de Pascal Boutrais, fondateur de
              l’entreprise. Portés par les récits de nos ancêtres explorateurs, corsaires et grands marins bretons,
              Pascal a décidé de partir à la découverte de nouvelles terres. En 1999, l’Irlande lui a ouvert ses portes
              et il y a établi, avec des ostréiculteurs français, nos premières cultures de coquillages. Parfait
              autodidacte, il a appris et organisé la culture des huîtres en s’adaptant et en maîtrisant son
              environnement.
            </p>
            <p>
              Au fil des années, notre expérience nous a permis d’acquérir un savoir-faire qui, allié aux richesses
              naturelles de notre environnement, nous a permis d’obtenir une huître dotée de caractéristiques
              exceptionnelles et d’un goût extraordinaire, un produit hors du commun.
            </p>
            <p>
              Aujourd’hui, Hugo, formé par son père, tient les rênes de la Famille Boutrais depuis plus de 5 ans. Son
              ambition est claire : améliorer sans cesse notre travail afin de maîtriser notre produit, depuis nos parcs
              ostréicoles jusqu’à votre assiette.
            </p>
            <img v-lazy="sortOyster" alt="oyster" class="image">
          </div>
        </section>
        <section class="second">
          <div class="main-title">
            <h2>Le mot de Hugo Boutrais</h2>
          </div>
          <div class="sub-title">
            <h3>Directeur de La Famille Boutrais</h3>
          </div>
          <div class="separate">
            <div>
              <img v-lazy="HugoConq" alt="hugo-boutrais">
            </div>
            <div class="wrapper">
              <p>
                “La Famille Boutrais est renommée dans le domaine de la production d'huîtres grâce à sa solide
                réputation. Notre savoir-faire unique, acquis au fil des années, nous permet de maîtriser chaque étape
                de notre travail, depuis la culture de nos huîtres dans nos parcs ostréicoles jusqu'à leur livraison
                dans vos assiettes.
              </p>
              <p>
                Nous sommes passionnés par notre métier et nous sommes déterminés à offrir à nos clients une expérience
                gustative grâce à nos produits dotés de caractéristiques uniques au goût inimitable.
              </p>
              <p>
                La Famille Boutrais est composée d'hommes et de femmes passionnés qui travaillent sans relâche pour
                améliorer constamment notre production. Nous croyons en la confiance, l'enthousiasme et le sérieux comme
                valeurs essentielles pour accomplir notre travail avec succès et satisfaire pleinement nos clients, nos
                meilleurs soutiens et supporters. Nous avons ainsi l'obligation de garantir une qualité digne de notre
                maison.”
              </p>
            </div>
          </div>
        </section>
        <section class="third">
          <div class="main-title">
            <h2>Un savoir-faire unique</h2>
          </div>
          <div class="sub-title">
            <h3>Un processus de prodution complexe </h3>
          </div>
          <div class="wrapper">
            <p>
              Notre savoir-faire réside dans notre capacité à nous adapter à notre environnement. Nos explorations nous
              ont permis d’établir nos cultures dans des eaux de grande qualité. Certaines de nos baies ne sont pas
              ouvertes sur la mer comme la majorité des baies.
            </p>
            <p>
              Ces spécificités nous ont donné l’opportunité de développer une nouvelle technique de production que nous
              sommes les seuls à pratiquer : la pousse en conche.
            </p>
          </div>
        </section>
        <section class="fourth">
          <div class="main-title">
            <h2>Une richesse environnementale</h2>
          </div>
          <div class="sub-title">
            <h3>Des terroirs d'excellence</h3>
          </div>
          <div class="separate">
            <div class="wrapper">
              <p>
                Une conche est une baie naturelle enclavée dans les terres qui est reliée à la mer par une très petite
                embouchure. Cette baie est souvent alimentée par un important afflux d’eau douce qui permet la création
                des nutriments nécessaires au bon développement des huîtres.
              </p>
              <p>
                Avec la pousse en conche, la Famille Boutrais vient révolutionner les saveurs. En effet, le produit qui
                résulte de cette production sort réellement de l’ordinaire : son goût est sucré et doux, elle est plus
                charnue et installée dans une coquille robuste et sombre en forme de goutte délicate.
              </p>
              <p>
                Nous possédons six exploitations ostréicoles implantées en France et en Irlande, sur lesquelles sont
                installées des équipes capables de travailler avec le même savoir-faire.
              </p>
              <p>
                En France, nos parcs sont situés à Cancale, dans la
                <router-link to="/a-propos/baie-saint-michel">baie du Mont-Saint-Michel</router-link>
                , et nous avons également
                un site d’emballage sur l’île d’Oléron, en Charente-Maritime.
              </p>
              <p>
                Nos exploitations irlandaises sont situées aux quatre coins du pays. Au Sud-Est se trouve la conche de
              <router-link to="Bannow">Bannow</router-link>
                . La baie de Clew se trouve au nord-ouest du pays. Dans le comté de Donegal, nous avons la baie de
                <router-link to="Trawenagh">Trawenagh</router-link>
                et la baie de
                <router-link to="Dundrum">Dundrum</router-link>
                est quant à elle située en Irlande du Nord.
              </p>
              <p>
                Chacun de nos parcs possède des caractéristiques bien distinctes et a une mission bien précise. Nos
                parcs de demi-élevage sont des parcs où les huîtres vont être élevées de l’état de naissain jusqu’au
                stade du demi-élevage, soit environ 18 mois. Les lots sont ensuite triés et envoyés dans nos différents
                parcs ostréicoles pour terminer leur croissance suivant la gamme d’huîtres que nous souhaitons obtenir.
                Dans chaque cas, notre traçabilité est dûment suivie, et notre travail est effectué avec rigueur et
                enthousiasme.
              </p>
            </div>
            <div>
              <img v-lazy="solo" alt="solo">
            </div>
          </div>
        </section>



      </div>
    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import sortOyster from '@/assets-2/about/who_are_we/sort-oysters.webp';
import HugoConq from '@/assets-2/about/who_are_we/hugo_conquerant.webp';
import solo from '@/assets-2/about/who_are_we/solo.webp';

const CategoryBanner = () => import ("@/components/CategoryBanner.vue")

export default {
  name: "WhoAreWe",
  data () {
    return {
      sortOyster,
      HugoConq,
      solo,
    }
  },
  beforeMount () {
    this.$store.dispatch('metaData/getMetaData', 10)
  },computed: {
    ...mapGetters('metaData', ['getMeta']),
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta:[
        // meta données générales
        {  name: 'title', content: this.getMeta.meta_title},
        { name: 'description', content: this.getMeta.meta_desc},
        { name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMeta.meta_title},
        { property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {CategoryBanner},
  mounted() {
    this.$store.commit('category/setCategory', 10)
  },
  methods: {
    ...mapActions('metaData', ['getMetaData']),
  },
}
</script>

<style lang="scss" scoped>
.container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 2% 7% 0% 7%;
}

.main-col {
  @include flex(flex-start, flex-start, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 90vw;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
  }

  section {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    margin-bottom: 20px;

    .main-title {
      @include flex(flex-start, center, $gap: 8px);
      position: relative;

      h2 {
        color: $Black;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        letter-spacing: 1px;
      }

      img {
        width: 10%;
      }
    }

    .sub-title {
      margin-bottom: 25px;

      h3 {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .wrapper {
      @include flex(space-between, flex-start, column, $gap: 25px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
      }
      @media screen and (max-width: 575.99px) {
        gap: 10px;
      }

      .name {
        font-weight: 700;
      }

    }

  }

  .next-step {
    margin-left: auto;
  }
}

.separate {
  @include flex(space-between, $gap: 25px);

  @media screen and (max-width: 1752px) {
    flex-direction: column;
  }
  @media screen and (max-width: 575.99px) {
    gap: 10px;
  }

  img {
    height: 100%;
    max-width: none;
    width: auto;
  }
}

.image {
  display: block;
  margin-right: auto;
  margin-left: auto;
}


</style>