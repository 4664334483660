<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="title-container">

      </div>
      <section>
        <ul class="shop__list">
          <li class="item" v-for="point in pointsOfSale">
            <div class="item__image-container">
              <img v-lazy="logoLfb" alt="https://lafamilleboutrais.com">
            </div>
            <div class="item__meta">
              <h3>{{ point.display_name }}</h3>
              <p>{{ point.street }},<br> {{ point.zip }} {{ point.city }}</p>
              <p>{{ point.comment }}</p>
              <button aria-label="go" @click="recenterMap([point.latitude, point.longitude]);"
                      type="button" class="button full">J'y vais !
              </button>
            </div>
          </li>
        </ul>

        <!-- TODO Google Map -->
        <div id="map">
          <l-map style="height: 300px" ref="myMap" :zoom="zoom" :center="center">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker v-for="point in pointsOfSale" :lat-lng="[point.latitude, point.longitude]"></l-marker>
          </l-map>
          <!--					<iframe src="https://www.openstreetmap.org/export/embed.html?bbox=-2.287559509277344%2C48.52206208716404%2C-1.7142105102539065%2C48.72041422804209&amp;layer=mapnik"></iframe>-->
        </div>

      </section>
    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getAPI} from "@/services/axios-api";
import {map} from "leaflet";
import logoLfb from '@/assets-2/shop/logo_lfb.webp';
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
const CategoryBanner = () => import ("@/components/CategoryBanner.vue")
delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})


export default {
  name: "PointsOfSales",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    L,
    CategoryBanner,
  },
  data() {
    return {
      logoLfb,
      pointsOfSale: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 9,
      center: [48.572973, -1.928101],
    }
  },
  beforeMount() {
    this.$store.dispatch('metaData/getMetaData', 17)
  },
  computed: {
    ...mapGetters('metaData', ['getMeta']),
    ...mapGetters('auth', ['getLocation']),
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta: [
        // meta données générales
        {name: 'title', content: this.getMeta.meta_title},
        {name: 'description', content: this.getMeta.meta_desc},
        {name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        {property: 'og:type', content: "website"},
        {property: 'og:url', content: "https://lafamilleboutrais.com/"},
        {property: 'og:title', content: this.getMeta.meta_title},
        {property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  mounted() {
    const headerHeight = document.querySelector('header').offsetHeight
    const map = document.querySelector('#map')
    map.style.top = `${headerHeight + 10}px`
    this.getPointOfSale()
    this.$store.commit('category/setCategory', 17)
  },
  methods: {
    map,
    ...mapActions('metaData', ['getMetaData']),
    getPointOfSale: async function () {
      await getAPI
          .post('/shipping/clickncollect/', {'location': this.getLocation, multiple: false})
          .then(res => {
            this.pointsOfSale = res.data;
          })
    },
    recenterMap: function (coordinate) {
      this.$refs.myMap.mapObject.panTo(coordinate);
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (min-width: 992px) {
    width: 90vw;
  }
  @media screen and (min-width: 1200px) {
    width: 80vw;
  }
}

.title-container {
  width: 100%;
  text-align: center;
  margin: 50px 0 45px;

  @media screen and (min-width: 992px) {
    margin: 50px 0 75px;
  }

  h2 {
    color: $LightBrown;
    font-size: 35px;

    @media screen and (min-width: 768px) {
      font-size: 45px;
    }

    @media screen and (min-width: 992px) {
      font-size: 56px;
    }
  }
}

section {
  @include flex(space-between, center, column-reverse, $gap: 50px);
  width: 100%;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
  }

  .shop__list {
    @include flex(flex-start, flex-start, column);
    flex: 1;

    .item {
      @include flex(space-between, center, column, $gap: 10px);
      @media screen and (min-width: 992px) {
        flex-direction: row;
        gap: 50px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $Grey;
        padding-bottom: 25px;
      }

      &:not(:first-child) {
        padding-top: 25px;
      }

      &__image-container {
        @include flex(center, center);
        flex: 1;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &__meta {
        @include flex(center, center, column, $gap: 5px);
        flex: 1;
        text-align: center;
        padding: 5px 0;

        @media screen and (min-width: 992px) {
          gap: 3px
        }

        button {
          margin-top: 10px;
          @media screen and (min-width: 992px) {
            margin-top: 5px;
          }
        }
      }
    }
  }

  #map {
    @include flex(center, center);
    flex: 1;
    position: initial;
    height: 40vh;
    min-height: 40vh;
    width: 100%;
    z-index: 19;

    @media screen and (min-width: 992px) {
      @include position(0, 0, $position: sticky);
      height: 400px;
      min-height: unset;
    }

    iframe {
      width: 100%;
      height: 100%;
      min-height: 40vh;
      border: 0;

      @media screen and (min-width: 992px) {
        min-height: unset;
      }
    }
  }
}

</style>