<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="main-col">
        <section class="first">
          <div class="main-title">
            <div>
              <h2>Les parcs à huîtres de Cancale</h2>
              <div class="sub-title">
                <h3>Patrimoine de l'Unesco</h3>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <p>
              En tant que Bretons, natifs de Saint-Malo, nous avons naturellement le désir et l’envie de produire une
              huître Bretonne. En 2017, nous avons saisi l’opportunité de reprendre une ferme ostréicole basée à
              Saint-Méloir-des-Ondes, près de Cancale.
            </p>
            <p>
              Ainsi, nous continuons d’écrire l’histoire des « Huîtres de Céline », des huîtres et une marque issue de
              plusieurs générations d’ostréiculteurs, déjà inscrites comme référence incontournables des produits
              Cancalais.
            </p>
            <p>
              C’est près de Saint-Malo et dans la baie du Mont-Saint-Michel que se trouvent nos parcs à huîtres. Connue
              pour ses huîtres creuses si particulières, la ville de Cancale se révèle comme la « capitale de l’huître »
              en France.
            </p>
          </div>
        </section>
        <section class="second">
          <div class="main-title">
            <div>
              <h2>La baie du Mont-Saint-Michel</h2>
              <div class="sub-title">
                <h3>Les plus grande marée d’Europe</h3>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <p>
              C’est dans la baie du Mont-Saint-Michel et non loin de Saint-Malo que se trouvent les fameux parcs à huîtres de Cancale.
            </p>
            <p>
              Connue pour ses huîtres creuses si particulières, la ville se révèle être une escale obligatoire pour tous
              les gourmands et curieux d’huîtres. En effet, les touristes viennent de la France entière et des îles
              britanniques pour avoir l’opportunité de goûter les cancalaises.
            </p>
            <p>
              C’est après 1850, qu’un appauvrissement des bancs naturels d’huîtres plates a lieu, laissant les pêcheurs
              devenir peu à peu éleveurs d’huîtres creuses. C’est sur presque 400 hectares de parcs que les huîtres
              grandissent dans des poches fixées sur des tables installées en lignes ; ces poches sont retournées à
              chaque grande marée.
            </p>
            <p>
              C’est dans la baie du Mont-Saint-Michel que s’effectuent les plus grandes marées d’Europe : quatorze
              mètres de marnage. C’est grâce au courant qu’un brassage important de l’eau est effectué, favorisant le
              renouvellement du plancton, nutriment essentiel au bon développement de l’huître. Tous les éléments sont
              alors réunis pour obtenir une huître iodée et charnue, qui plaît tant aux gourmands !
            </p>
            <p>
              La baie du Mont-Saint-Michel présente une caractéristique intéressante. En effet, elle est approvisionnée
              d’est en ouest en eau douce par la Sée, le Couesnon et la Sélune. Les planctons nécessaires à l’huître
              sont donc apportés par le courant généré par l’effet des marées et le déversement naturel des rivières.
            </p>
            <img :src="currentImageUrl" loading="lazy" alt="mont-saint-michel-bay">
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import CategoryBanner from "@/components/CategoryBanner.vue";
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/services/utils";

export default {
  name: "SaintMichel",
  beforeMount () {
    this.$store.dispatch('metaData/getMetaData', 11)
  },
  computed: {
    ...mapGetters('metaData', ['getMeta']),
  },
  data() {
    return {
      currentImageUrl: '',
      srcset: 'about/cancale/baie-mont-saint-michel-small.jpg 320w,about/cancale/baie-mont-saint-michel-medium.jpg 640w,about/cancale/baie-mont-saint-michel-big.jpg 1280w',
    }
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta:[
        // meta données générales
        {  name: 'title', content: this.getMeta.meta_title},
        { name: 'description', content: this.getMeta.meta_desc},
        { name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMeta.meta_title},
        { property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {CategoryBanner},
  mounted() {
    this.$store.commit('category/setCategory', 11)
    this.updateImageSrc();
    window.addEventListener('resize', this.updateImageSrc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageSrc);
  },
  methods: {
    ...mapActions('metaData', ['getMetaData']),
    updateImageSrc() {
      this.currentImageUrl = Utils.getImageSrc(this.srcset);
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 2% 7% 0% 7%;
}

.main-col {
  @include flex(flex-start, flex-start, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 90vw;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
  }

  section {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    margin-bottom: 20px;

    .main-title {
      @include flex(flex-start, center, $gap: 8px);
      position: relative;

      h2 {
        color: $Black;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        letter-spacing: 1px;
      }

      img {
        width: 10%;
      }
    }

    .sub-title {
      margin-bottom: 25px;

      h3 {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .wrapper {
      @include flex(space-between, flex-start, column, $gap: 25px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
      }
      @media screen and (max-width: 575.99px) {
        gap: 10px;
      }

      .name {
        font-weight: 700;
      }

      .buttons__container {
        @include flex(flex-start, flex-start, column, $gap: 8px);
        flex: 1;
        max-width: 200px;
        @media screen and (max-width: 767.99px) {
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          max-width: unset;
        }

        .button {
          font-size: 15px;
          padding: 10px 10px;
          letter-spacing: .5px;
          flex: 1;
          @media screen and (max-width: 767.99px) {
            width: fit-content;
            flex: auto;
          }
          @media screen and (max-width: 575.99px) {
            width: 100%;
          }
        }
      }
    }

  }

  .next-step {
    margin-left: auto;
  }
}
</style>