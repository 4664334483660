<template>
	<div class="category_banner" :style="getStyle(getCategory)">
		<div class="category_textes">
			<h2>{{ $t(getCategory.name) }}</h2>
			<p class="subtitle">{{ $t(getCategory.description) }}</p>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "CategoryBanner",
  computed: {
    ...mapGetters('category', ['getCategory']),
  },
  methods: {
    getStyle: function (banner) {
      let bgImage = "";
      const screenWidth = window.innerWidth;

      if (screenWidth <= 320) {
        bgImage = require('@/assets-2/' + this.getImageFromSrcset(banner.srcset, "320w"));
      } else if (screenWidth <= 640) {
        bgImage = require('@/assets-2/' + this.getImageFromSrcset(banner.srcset, "640w"));
      } else {
        bgImage = require('@/assets-2/' + this.getImageFromSrcset(banner.srcset, "1280w"));
      }

      return {
        backgroundImage: `url("${bgImage}")`
      }
    },
    getImageFromSrcset: function (srcset, size) {
      const images = srcset.split(',').map(item => item.trim());
      for (let img of images) {
        if (img.includes(size)) {
          return img.split(' ')[0];
        }
      }
      return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.category_banner {
	height: clamp(150px, 30vh, 500px);
	width: 100%;
	background-size: cover;
	background-position: center, center;
	position: relative;
	@include flex(center, center);

	&::before {
		@include position(0,0);
		width: 100%;
		height: 100%;
		background-color: rgba(46, 85, 118, 0.8);
	}

	.category_textes {
		@include flex(center, center, column);
		width: 100%;
		height: 100%;
		position: relative;
		color: $White;
		padding: 0 4%;

		@media screen and (min-width: 768px) {
			padding: 0 20%;
		}

		h2 {
			font-size: clamp(2.5rem, 4.5vw, 5rem);
			font-weight: 700;
      text-align: center;
		}
		p {
			font-size: 1rem;
			font-weight: lighter;
			line-height: 200%;
      text-align: center;

			@media screen and (min-width: 992px) {
				line-height: 150%;
			}
		}
	}
}
</style>