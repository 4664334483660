<template>
  <main>
    <CategoryBanner/>
    <div class="container">
      <div class="main-col">
        <section class="first">
          <div class="main-title">
            <div>
              <h2>La conche de Bannow</h2>
              <div class="sub-title">
                <h3>Terroir de l’huître Ostra Regal</h3>
              </div>
            </div>
          </div>

          <div class="wrapper">
            <p>
              La conche de Bannow donne naissance à l’huître Ostra Regal. Après l’étape de demi-élevage, l’huître va
              passer 2 ans et demie dans cette baie que l’on appelle conche.
            </p>
            <p>
              La conche de Bannow est un terroir exceptionnel situé au sud-est de l’Irlande. Espace classé en zone de
              protection spéciale des oiseaux, le baie de Bannow tient en toile de fond, la péninsule de Hook où se
              dresse le plus ancien phare d’Irlande.
            </p>
            <p>
              La conche est semblable à une grande claire naturelle, ces bassins argileux peu profonds. Enclavée dans
              les terres, cette réserve d’oiseaux de plus de 10 000 hectares a, comme unique lien avec la mer, une
              embouchure large d’une centaine de mètres. Malgré son caractère semi-fermé et sa surface gigantesque, la
              conche de Bannow se vide et se remplit entièrement à chaque marée, permettant un renouvellement continuel
              des eaux.
            </p>
            <p>
              De part et d’autre ruissellent quelques rivières et ruisseaux approvisionnant les huîtres en eau douce
              ainsi qu’en nutriments, minéraux et oligo éléments.
              Le sol argilo-calcaire permet un bon écoulement mais retient ces derniers jusqu’à la marée suivante. On
              retrouve dans la conche une très forte concentration de plancton due à l’ensoleillement et aux eaux
              saumâtres (Une eau saumâtre est une eau dont la teneur en sel est sensiblement inférieure à celle de
              l'eau de mer).
            </p>
            <p>
              L’ensemble de ces conditions nous permet d’élever l’Ostra Regal. La densité d’élevage est de deux huîtres
              au mètre carré. L’huître est manipulée plus de soixante fois pour contenir la croissance du coquillage et
              favoriser l’engraissement. Deux marées journalières découvrent entièrement les parcs forçant l’huître à
              rester fermée 12 heures par jour, améliorant ainsi le goût et la dureté de la coquille.
            </p>
            <p>
              Ce sont toutes ces conditions réunies qui confèrent à l’Ostra Regal son goût unique, entre sucré et salé,
              à la frontière de la terre et de la mer.
            </p>
            <img :src="currentImageUrl" loading="lazy" alt="bannow-bay">
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import CategoryBanner from "@/components/CategoryBanner.vue";
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/services/utils";


export default {
  name: "Bannow",
  data () {
    return {
      srcset: 'about/bannow/bannow_small.png 320w,about/bannow/bannow_medium.png 640w,about/bannow/bannow_big.png 1280w',
      currentImageUrl: ''
    }
  },
  beforeMount () {
    this.$store.dispatch('metaData/getMetaData', 13)
  },
  computed: {
    ...mapGetters('metaData', ['getMeta']),
  },
  metaInfo() {
    return {
      title: this.getMeta.template,
      meta:[
        // meta données générales
        {  name: 'title', content: this.getMeta.meta_title},
        { name: 'description', content: this.getMeta.meta_desc},
        { name: 'robots', content: 'index,follow'},
        // pour SEO FB et Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMeta.meta_title},
        { property: 'og:description', content: this.getMeta.meta_desc},
        // { property: 'og:image', content: this.getCategory.image },
      ],
    }
  },
  components: {CategoryBanner},
  mounted() {
    this.$store.commit('category/setCategory', 13)
    this.updateImageSrc();
    window.addEventListener('resize', this.updateImageSrc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageSrc);
  },
  methods: {
    ...mapActions('metaData', ['getMetaData']),
    updateImageSrc() {
      this.currentImageUrl = Utils.getImageSrc(this.srcset);
    },
  },
}
</script>

<style lang="scss" scoped>


.container {
  @include flex(space-between, center, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 2% 5%;
}

.main-col {
  @include flex(space-between, center, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 90vw;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
  }

  section {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    margin-bottom: 20px;

    .main-title {
      @include flex(flex-start, center, $gap: 8px);
      position: relative;

      h2 {
        color: $Black;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        letter-spacing: 1px;
      }

      img {
        width: 10%;
      }
    }

    .sub-title {
      margin-bottom: 25px;

      h3 {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 15px;
      }
    }

    .wrapper {
      @include flex(space-between, center, column, $gap: 25px);
      width: 100%;

      @media screen and (max-width: 767.99px) {
        flex-direction: column;
      }
      @media screen and (max-width: 575.99px) {
        gap: 10px;
      }

      p{
        width: 0;
        min-width: 100%;
      }

      .name {
        font-weight: 700;
      }

      .buttons__container {
        @include flex(flex-start, flex-start, column, $gap: 8px);
        flex: 1;
        max-width: 200px;
        @media screen and (max-width: 767.99px) {
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          max-width: unset;
        }

        .button {
          font-size: 15px;
          padding: 10px 10px;
          letter-spacing: .5px;
          flex: 1;
          @media screen and (max-width: 767.99px) {
            width: fit-content;
            flex: auto;
          }
          @media screen and (max-width: 575.99px) {
            width: 100%;
          }
        }
      }
    }

  }

  .next-step {
    margin-left: auto;
  }
}
</style>